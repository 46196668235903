import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

export const MatrixTitle = (props) => {
  const [output, setOutput] = useState();
  const text = props.text;
  const fontSize = props.fontSize;

  useEffect(() => {
    setOutput(makeHeader(text));
    window.addEventListener("resize", () => {
      setOutput(makeHeader(text));
    });

    const interval = setInterval(() => {
      const fillerTexts = document.querySelectorAll(
        ".matrixHeader > .innerMatrixHeader > .fillerText"
      );
      const fillerSpan =
        fillerTexts[Math.floor(Math.random() * fillerTexts.length)];
      if (fillerSpan) {
        const existingText = fillerSpan.innerHTML;
        const index = Math.floor(Math.random() * existingText.length);
        const newText =
          existingText.substr(0, index) +
          getFiller(true) +
          existingText.substr(index + 1);
        fillerSpan.innerHTML = newText;
      }
    }, 100);
    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const getFiller = (two) => {
    const fillers = ["0", "1"];
    if (two) {
      const twoRandom = Math.floor(Math.random() * 200);
      if (twoRandom === 13) {
        return 2;
      }
    }
    return fillers[Math.floor(Math.random() * fillers.length)];
  };

  const makeHeader = (lines) => {
    const totalLine =
      1.6 * Math.ceil(document.body.clientWidth / (fontSize * 16));
    const output = lines.map((x, i) => (
      <>
        <span key={2 ** (i + 1)} className="fillerText">
          {Array.from({ length: Math.floor((totalLine - x.length) / 2) }, () =>
            getFiller()
          ).join("")}
        </span>
        <span key={3 ** (i + 1)} className="realText">
          {x}
        </span>
        <span key={5 ** (i + 1)} className="fillerText">
          {Array.from({ length: Math.ceil((totalLine - x.length) / 2) }, () =>
            getFiller()
          ).join("")}
        </span>
        <br />
      </>
    ));
    return (
      <div className="innerMatrixHeader">
        <span className="fillerText">
          {Array.from({ length: totalLine }, () => getFiller()).join("")}
        </span>
        <br />
        {output}
        <span className="fillerText">
          {Array.from({ length: totalLine }, () => getFiller()).join("")}
        </span>
      </div>
    );
  };

  return (
    <Row>
      <Col className="matrixHeader text-center">{output}</Col>
    </Row>
  );
};
