import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import roomWEBP from "./assets/room.webp";
import "./App.scss";
import { MatrixTitle } from "./components/matrixTitle";

function App() {
  return (
    <Container fluid className="App px-0 pb-5">
      <Row className="pageSection pb-4 mb-5">
        <Col className="mx-0 px-0 d-flex flex-column">
          <Row className="mb-3 mb-sm-5">
            <Col
              md="auto"
              className="mx-auto text-center d-sm-block d-none"
              style={{ fontSize: "4rem" }}
            >
              <MatrixTitle text={["Serta", "Technologies"]} fontSize={4} />
            </Col>
            <Col
              md="auto"
              className="mx-auto text-center d-sm-none d-block"
              style={{ fontSize: "2rem" }}
            >
              <MatrixTitle text={["Serta", "Technologies"]} fontSize={2} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={10} className="mx-auto">
              <Row className="mb-3">
                <Col>
                  <h1 style={{ fontSize: "2em" }}>
                    <span className="prompt" style={{ marginRight: "0.6rem" }}>
                      $
                    </span>
                    welcome to serta
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col className="subSection">
                  Serta Technologies is a technical consultancy firm, experts in
                  helping clients to understand the possibilities of technology,
                  and in designing and building technical solutions to everyday
                  problems.
                  <br />
                  We do everything from simple websites, complex inventory
                  management systems, to e-learning and special-interest
                  community platforms.
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pageSection ">
        <Col xs={12} lg={10} className="mx-auto">
          <Row className="mb-3">
            <Col>
              <h2 style={{ fontSize: "2em" }}>
                <span className="prompt" style={{ marginRight: "0.6rem" }}>
                  $
                </span>
                step into the office, and see what we can do
              </h2>
            </Col>
          </Row>
          <Row>
            <Col
              className="subSection d-flex flex-column justify-content-center"
              lg={6}
            >
              <div className="mb-2">
                Get in touch, and we will discuss the problems that you are
                facing, and any potential solutions. If we feel that we are a
                good fit, we will provide you with a quote, and if not, we will
                do our best to advise you about where to go next.
              </div>
              <div className="mt-2">
                <a href="mailto: enquiries@sertatechnologies.co.uk">
                  enquiries@sertatechnologies.co.uk
                </a>
              </div>
            </Col>
            <Col
              lg={6}
              className="d-flex flex-column"
              style={{ justifyContent: "center" }}
            >
              <Image fluid src={roomWEBP} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="text-center mt-5">
        <Col>
          <div>
            Serta Technologies Ltd. Ivy Chimneys, Epping, Essex, CM16 4EL
          </div>
          <div>Company Registration Number: 09547275</div>
          <div>VAT Registration Number: 250318339</div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
